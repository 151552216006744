<template>
  <div class="districtCounty">
    <div class="districts">
      <div class="district-header">
        <div class="district-img" @click="back">
          <img src="../../image/twoThreeImgs/return.png" alt="" />
        </div>
        <div class="county-text">
          <div class="county-title">{{ distinguish }}</div>
          <div class="district-time">
            <div>{{ nowDate }}</div>
            <div>{{ nowWeek }}</div>
            <div>{{ nowTime }}</div>
          </div>
        </div>
      </div>
      <div>
        <div style="cursor: pointer" @click="buttoncli">
          <img v-if="isFullscreen" src="../../image/top/guanbi.png" alt="" />
          <img v-else src="../../image/top/quanping.png" alt="" />
        </div>
      </div>
    </div>
    <div class="district-viewmain">
      <left ref="left"></left>
      <middle ref="middle"></middle>
      <right ref="right"></right>
    </div>
  </div>
</template>

<script>
import screenfull from 'screenfull'
import left from '../districtCounty/left/index.vue'
import middle from '../districtCounty/middle/index.vue'
import right from '../districtCounty/right/index.vue'
export default {
  components: {
    left,
    middle,
    right,
  },
  data() {
    return {
      //时间
      timer: null,
      nowWeek: '',
      nowDate: '',
      nowTime: '',
      //全屏
      isFullscreen: false,
      distinguish: '',
    }
  },
  mounted() {
    //刚加载就启动计时器
    this.timer = setInterval(() => {
      this.setNowTimes()
    }, 1000)
    this.distinguish = this.$route.query.name
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    // 时间
    setNowTimes() {
      let myDate = new Date()
      let wk = myDate.getDay()
      let yy = String(myDate.getFullYear())
      let mm =
        myDate.getMonth() + 1 < 10
          ? '0' + (myDate.getMonth() + 1)
          : myDate.getMonth() + 1
      let dd = String(
        myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
      )
      let hou = String(
        myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
      )
      let min = String(
        myDate.getMinutes() < 10
          ? '0' + myDate.getMinutes()
          : myDate.getMinutes()
      )
      let sec = String(
        myDate.getSeconds() < 10
          ? '0' + myDate.getSeconds()
          : myDate.getSeconds()
      )
      let weeks = [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ]
      let week = weeks[wk]
      this.nowDate = yy + '-' + mm + '-' + dd
      this.nowTime = hou + ':' + min + ':' + sec
      this.nowWeek = week
    },
    // 全屏
    buttoncli() {
      //   判断浏览器是否支持全屏
      if (!screenfull.isEnabled) {
        this.$message({
          message: '你的浏览器不支持全屏',
          type: 'warning',
        })
        return false
      }
      // 将样式改为锁屏
      if (this.isFullscreen == false) {
        //   打开全屏
        this.isFullscreen = true
        screenfull.toggle()
      } else {
        this.isFullscreen = false
        screenfull.toggle()
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
}
</script>

<style>
.districtCounty {
  background: url(../../image/top/bigbackground3.png) no-repeat 0 0 / contain;
  background-size: 100% 100%;
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
}
.districts {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: url(../../image/top/bigbackground2.png) no-repeat 0 0 / contain;
  background-size: 100% 100%;
  width: 100%;
  height: 3rem;
  color: #a6bafd;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  margin-bottom: 0.3rem;
}
.district-header {
  display: flex;
  align-items: center;
}
.district-img {
  margin-right: 0.4rem;
}
.county-text {
  color: #a6bafd;
}
.district-time {
  display: flex;
}
.district-time > div {
  margin-right: 0.4rem;
}
.county-title {
  font-size: 0.6rem;
  margin-bottom: 0.2rem;
  font-weight: bold;
}

.district-viewmain {
  min-width: 1024px;
  max-width: 1920px;
  min-height: 780px;
  margin: 0 auto;
  display: flex;
  padding: 0 0.5rem 0;
}
.leftItem {
  flex: 3;
}
.middleItem {
  flex: 3.8;
  margin: 0rem 0.3rem;
}
.rightItem {
  flex: 3;
}
</style>
