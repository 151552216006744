<template>
  <div class="leftItem main">
    <populationOverview :areaRatinAge="areaRatinAge" />
    <olderInfor :areaRatinAge="areaRatinAge" />
    <comprehensive />
  </div>
</template>
<script>
import { getAreaRatioAge } from '@/api/secondaryPage'
export default {
  components: {
    populationOverview: () => import('./component/populationOverview.vue'),
    olderInfor: () => import('./component/olderInfor.vue'),
    comprehensive: () => import('./component/comprehensive.vue'),
  },
  data() {
    return {
      areaRatinAge: {
        total: 0, //老龄人口总数
        old: {
          total: 0, //总人数
          num: 0, //老龄人口总数
          percent: '50', //比例
        },
        gender: {
          MALE: {
            total: 0, //老龄化人数
            num: 20, //男的人数
            percent: '0%', //比例
          },
          FEMALE: {
            total: 0, //老龄化人数
            num: 80, //女的人数
            percent: '0%', //比例
          },
        },
        census: {
          CITIZEN: {
            total: 20, //总数
            num: 0, //城镇总数
            percent: '0%', //比例
          }, //城镇
          AGRICULTURE: {
            total: 80, //总数
            num: 0, //农业总数
            percent: '0%', //比例
          }, //农业
        }, //户口
        ageGroup: [],
      },
    }
  },
  mounted() {
    this.getAreaRatioAge()
  },
  methods: {
    getAreaRatioAge() {
      const { code = '510104' } = this.$route.query
      getAreaRatioAge(code).then((res) => {
        if (res.data.code == 0) {
          this.areaRatinAge = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
  },
}
</script>
<style scoped>
.main {
  width: 24.4rem;
}
</style>
