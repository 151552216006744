<template>
  <div class="service">
    <div>
      <div class="service-title"></div>
    </div>
    <div class="conten">
      <div class="left">
        <div class="item">
          <p class="spcol">{{ insmes.train.manNum.accTotal }}</p>
          <p>累计培训数据</p>
        </div>
        <div class="item">
          <p>{{ insmes.train.manNum.social }}</p>
          <p>社工组织(培训数据)</p>
        </div>
        <div class="item">
          <p>{{ insmes.train.manNum.volunteer }}</p>
          <p>志愿者(志愿者)</p>
        </div>
        <div class="item">
          <p class="spcol">{{ insmes.train.manTime.accTotal }}</p>
          <p>累计培训人次</p>
        </div>
        <div class="item">
          <p>{{ insmes.train.manTime.social }}</p>
          <p>社工组织(培训数据)</p>
        </div>
        <div class="item">
          <p>{{ insmes.train.manTime.volunteer }}</p>
          <p>志愿者(志愿者)</p>
        </div>
      </div>
      <div class="right">
        <div class="item">
          <p class="spcol">{{ insmes.train.manTime.total }}</p>
          <p>社工组织总数据</p>
        </div>
        <div class="item">
          <p class="spcol">{{ insmes.train.manNum.total }}</p>
          <p>志愿者总数据</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAreaInsProfile } from '@/api/secondaryPage'

export default {
  data() {
    return {
      // property: 'value',
      insmes: {
        train: {
          manTime: {
            total: 0, //社工组织总人数
            accTotal: 0, //累计培训人数 0 社工组织 (培训人数)
            social: 0, //社工组织（培训人数）
            volunteer: 0, //志愿者
          }, //人次数据
          manNum: {
            total: 0, //社工组织总人数
            accTotal: 0, //累计培训人数 0 社工组织 (培训人数)
            social: 0, //社工
            volunteer: 0, //志愿者人数
          },
        }, //组织培训
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    //获取数据
    getData() {
      const { code } = this.$route.query
      //   let areaCode = 511822
      getAreaInsProfile(code).then((res) => {
        // console.log(res)
        if (res.data.code === 0) {
          this.insmes = res.data.data
        }
      })
    },
  },
}
</script>
<style scoped>
.service-title {
  height: 1.46rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service {
  color: #ffffff;
  background: url(../../../../image/twoThreeImgs/organization.png) 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
  position: relative;
  height: 9.6rem;
}
.conten {
  display: flex;
  justify-content: space-between;
  padding: 0.2rem 0.4rem 0 0.4rem;
}
.left {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: calc(100% - 6.2rem);
  height: 7.2rem;
  padding-right: 3px;
}
.right {
  width: 6.2rem;
  height: 7.2rem;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  background-size: 100% 100%;
  background: url(../../../../image/twoThreeImgs/orgright.png) 0 0 no-repeat;
  background-size: 100% 100%;
}
.right .item {
  /* width: 5.8rem; */
  width: 100%;
  height: 3.4rem;
  display: block;
}
.left .item {
  /* width: 5.3rem;
  height: 3.4rem;
  text-align: center;
  display: inline-block;
  background: url(../../../../image/twoThreeImgs/orgleft.png) 0 0 no-repeat;
  background-size: 100% 100%; */
  width: 5.3rem;
  height: 3.5rem;
  text-align: center;
  display: inline-block;
  background: url(../../../../image/twoThreeImgs/orgleft.png) 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 6px;
  margin-right: 3px;
  margin-left: 3px;
  flex-grow: 1;
}

.left p:first-child,
.right p:first-child {
  margin-top: 0.5rem;
  color: #b4d3fe;
  font-size: 1.25rem;
  margin-bottom: 0.1rem;
  font-weight: bold;
}
.spcol {
  color: #ff8529 !important;
}
.left p:last-child,
.right p:last-child {
  color: #8eb8e6;
  font-size: 0.58rem;
}
</style>
