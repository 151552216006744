<template>
  <!-- 下侧中右 -->
  <div class="under-security-cs">
    <div class="left-box-top"></div>
    <!-- <div class="left-box-bottom"></div> -->
    <div class="security-cs">
      <!-- 安全异常报告  -->
      <!-- <div class="title">蒙自2022适老化改造</div> -->
      <!-- 安全系统实时数据  -->
      <!-- <div>安全系统实时数据</div> -->
      <!-- 翻页屏 -->
      <el-carousel indicator-position="none" height="11rem">
        <el-carousel-item v-for="(item, index) in safty" :key="index">
          <div class="smalltitle-cs">({{ item.name }})</div>
          <ul class="csdul-cs">
            <li v-for="(v, i) in item.lists" :key="i">
              <span
                :style="v.remark == '异常' ? 'color:#b63219' : 'color: #8EB8E6'"
                >{{ v.key }}</span
              >
              <div
                :style="v.remark == '异常' ? 'color:#b63219' : 'color:#8EB8E6'"
              >
                <span
                  :class="v.remark == '异常' ? 'dian red' : 'dian gren'"
                ></span>
                <span>{{ v.remark }}</span>
                <!-- <span>{{ v.remark[1] }}</span> -->
              </div>
            </li>
          </ul>
        </el-carousel-item>
      </el-carousel>
      <div class="noMessage" v-if="safty.length === 0">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { getSafetyLiving } from '@/api/secondaryPage'
export default {
  //定义数据
  data() {
    return {
      // 安全系统实时数据
      data: {},
      safty: [
        // {
        //   name: '阳光敬老院',
        //   lists: [
        //     {
        //       key: '安全名称',
        //       remark: '正常',
        //     },
        //     {
        //       key: '安全名称',
        //       remark: '正常',
        //     },
        //     {
        //       key: '安全名称',
        //       remark: '正常',
        //     },
        //     {
        //       key: '安全名称',
        //       remark: '正常',
        //     },
        //     {
        //       key: '安全名称',
        //       remark: '正常',
        //     },
        //   ],
        // },
      ],
      code: '',
    }
  },

  mounted() {
    this.code = this.$route.query.code
    this.getData()
  },
  //定义函数
  methods: {
    getData() {
      const code = this.$route.query.code
      //   let code = 511822
      getSafetyLiving(code).then((res) => {
        console.log('安全数据', res)
        if (res.data.code === 0) {
          this.safty = res.data.data.safety
        }
      })
    },
  },
}
</script>

<style scoped>
/* 下侧中右 */
.under-security-cs {
  background: url(../../../../image/twoThreeImgs/safebg.png) 0 0 no-repeat;
  background-size: 100% 100%;
  width: 7.3rem;
  height: 13rem;
  padding: 0 0.4rem;
  box-sizing: border-box;
  display: inline-block;
}
.left-box-top {
  height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title,
.smalltitle-cs {
  color: #69b1ff;
  font-size: 0.58rem;
  font-weight: bold;
  margin-bottom: 0.4rem;
}
.csdul-cs {
  /* border: 1px solid red; */
  /* height: 14rem !important; */
}
.csdul-cs li {
  border-radius: 2px;
  /* width: 6.5rem; */
  height: 1.58rem;
  padding: 0 0.4rem;
  border: 1px solid #213060;
  background: rgba(5, 13, 38, 0.31);
  margin-bottom: 0.375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dian {
  display: inline-block;
  width: 6px;
  height: 6px;
  margin-right: 0.4rem;
  border-radius: 100%;
}
.gren {
  background: #32cf57;
}
.red {
  background: red;
}
/* 安全系统实时数据 */
/* .under-security-cs .security-cs > div:first-child {
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ff0000;
  background: url(../../../../image/top/safetytop.png) no-repeat center;
  background-size: 86% 100%;
  margin: 0 0.2rem;
  font-size: 0.68rem;
} */
/* .under-security-cs .security-cs > div:nth-child(2) {
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.65rem;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffd725;
}
/* 翻页屏 */
/* .under-security-cs .smalltitle-cs {
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #ffd725;
  font-size: 0.55rem;
}
.under-security-cs .csdul-cs {
  height: 7.5rem;
}  */

/* .under-security-cs .csdul-cs > li {
  height: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
} */
/* .under-security-cs .csdul-cs > li > span:first-child {
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #00d5ff;
  background: url(../../../../image/right/kuangluang.png) 0 0 no-repeat;
  background-size: 100% 100%;
  height: 1rem;
  width: 40%;
  font-size: 0.55rem;
  display: flex;
  justify-content: center;
  align-items: center;
} */
/* .under-security-cs .csdul-cs > li > div > span {
  background: url(../../../../image/right/jiaolang.png) 0 0 no-repeat;
  padding: 0.15rem 0.15rem;
  background-size: 100% 100%;
  margin-right: 0.2rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
} */
.noMessage {
  position: absolute;
  font-size: 21px;
  right: 1.9rem;
  top: 5rem;
  color: #2d3769;
}
</style>
<style>
/* 下侧中右 */
.under-security-cs {
  width: 13%;
  /* height: 100%; */
  margin-right: 0.3rem;
  position: relative;
}
</style>
