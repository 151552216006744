<template>
  <!-- 下侧右边 -->
  <div class="under-right-cs">
    <div class="left-box-top"></div>
    <div class="left-box-bottom"></div>
    <!-- 标题 -->
    <template class="right-title-cs"> </template>
    <!-- 监控 -->
    <div class="tabChange">
      <!-- 视屏 -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.name"
          :name="item.insNo"
          v-for="item in insMessage"
          :key="item.id"
        >
          <div class="video-cs" v-if="item.insNo == activeName">
            <div class="mask" @click="monitoringButton(item)" v-if="urls"></div>
            <iframe
              v-if="urls"
              :src="
                `https://open.ys7.com/ezopen/h5/iframe_se?url=${urls}&audio=1&accessToken=` +
                token
              "
              style="margin-top: -28%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              border="0"
              name="alimamaifrm"
              scrolling="no"
              height="172%"
              width="98%"
              id="iframe"
              allowfullscreen
            >
            </iframe>
            <div class="ifram-top">
              <span v-if="!urls"> 暂无数据 </span>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div v-if="this.insMessage.length === 0" class="noMessage">
        监控暂无数据
      </div>
    </div>
  </div>
</template>

<script>
// vuex中的数据
import { getDevices, getToken, getMonitoring } from '@/api/secondaryPage'
// import { getInstitutionInfo }  from '@/api/thirdPage'
export default {
  // 定义数据
  data() {
    return {
      // 视屏地址
      code: '',
      name: '',
      urls: null,
      data: {},
      activeName: '',
      insMessage: [],
      token: '',
    }
  },
  // dom加载成功
  mounted() {
    this.getData()
  },
  //计算属性
  computed: {},
  // 定义函数
  methods: {
    // 获取播放地址
    handleClick(tab, event) {
      this.handleGetToken(tab.name)
      this.getMonitoringMessage(tab.name)
    },
    handleGetToken(data) {
      getToken(data).then((res) => {
        if (res.data.code == 0) {
          this.token = res.data.data
        }
      })
    },
    getData() {
      const data = {
        code: this.$route.query.code,
      }
      this.code = this.$route.query.code
      this.name = this.$route.query.name
      getDevices(data).then((res) => {
        if (res.data.code === 0) {
          this.insMessage = res.data.data
          this.activeName = this.insMessage[0].insNo
          this.handleGetToken(this.insMessage[0].insNo)
          this.getMonitoringMessage(this.insMessage[0].insNo)
        }
      })
    },
    getMonitoringMessage(data) {
      getMonitoring({ insNo: data }).then((res) => {
        if (res.data.data.code == 0) {
          if (
            res.data.data.data == null
              ? true
              : res.data.data.data.length == 0
              ? true
              : false
          ) {
            this.urls = null
          } else {
            this.urls = res.data.data.data[0].url
          }
        }
      })
    },
    // 标题按钮
    monitoringButton(item) {
      this.$router.push('/videopage?insNo=' + item.insNo)
    },
  },
}
</script>

<style scoped>
.tabChange {
  width: 100%;
  box-sizing: border-box;
}
/* 标题 */
.under-right-cs {
  background: url(../../../../image/twoThreeImgs/videobg.png) 0 0 no-repeat;
  background-size: 100% 100%;
  display: inline-block;
  width: calc(100% - 7.6rem);
  height: 13rem;
  float: right;
}

.under-right-cs .right-title-cs {
  display: flex;
  justify-content: center;
  margin: 0rem 0.1rem 0rem 0.1rem;
  cursor: pointer;
}

.under-right-cs .right-title-cs > span {
  font-size: 0.8rem;
}

/* 视屏 */
.under-right-cs .video-cs {
  height: 11rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.video-cs {
  margin-top: 2%;
  cursor: pointer;
}
.ifram-top {
  height: 10rem;
  width: 100%;
  position: absolute;
  z-index: 999;
  right: 0.7rem;
  bottom: 0rem;
  top: 2rem;
}
.ifram-top > span {
  font-size: 24px;
  color: #2d3769;
  position: absolute;
  right: 5.2rem;
  top: 2.5rem;
}

#iframe {
  border: 0px;
  vertical-align: bottom;
  background: #3d3d3d;
}
.noMessage {
  position: absolute;
  color: #2d3769;
  font-size: 0.9rem;
  right: 4.6rem;
  top: 5.15rem;
}
.mask {
  position: absolute;
  height: 12rem;
  width: 15rem;
  background: transparent;
  z-index: 1100;
}
</style>
<style>
.el-tabs__nav-next,
.el-tabs__nav-prev {
  line-height: 35px;
}
.el-tabs__item {
  color: #ffd725;
  font-weight: 500 !important;
  font-size: 0.65rem;
  padding: 0 10px;
  height: 33px;
  line-height: 33px;
}
/* 下侧右边 */
.under-right-cs {
  width: 20%;
  position: relative;
}

.right-title-cs {
  height: 1.5rem;
  background: url(../../../../image/top/frametop.png) no-repeat center;
  background-size: 94% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.right-title-cs > span {
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #00d5ff;
  font-size: 1rem;
  /* margin-left: 1rem; */
  background: linear-gradient(
    92deg,
    #0072ff 0%,
    #00eaff 48.8525390625%,
    #01aaff 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.el-tabs__header {
  margin: 0;
}
.el-tabs__nav-wrap {
  padding: 0 0.4rem;
}
.el-tabs__nav-wrap::after {
  height: 0px;
}

.el-tabs__active-bar {
  background-color: none;
}
.el-tabs__item {
  color: #8eb8e6;
}
.el-tabs__item:hover,
.el-tabs__item.is-active {
  color: #ffffff;
}
.under-right-cs {
  display: flex;
  justify-content: center;
}
</style>
