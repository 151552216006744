<template>
  <div>
    <div class="service">
      <div>
        <div class="service-title"></div>
      </div>
      <div class="service-main">
        <div class="boxitem1">
          <div class="item">
            <div class="title">机构数据</div>
            <div class="conten">
              <ul>
                <li>
                  机构总数
                  <span class="spred">{{ insmes.ins.insNum.value }}</span>
                </li>
                <li>
                  公办机构 <span>{{ insmes.ins.pubInsNum.value }}</span>
                </li>
                <li>
                  民办机构 <span>{{ insmes.ins.peopleInsNum.value }}</span>
                </li>
                <li>
                  医养中心 <span>{{ insmes.ins.daInsNum.value }}</span>
                </li>
                <li>
                  失能半失能中心
                  <span>{{ insmes.ins.disableInsNum.value }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="title">床位数据</div>
            <div class="conten">
              <ul>
                <li>
                  床位总数
                  <span class="spred">{{ insmes.bed.pubInsNum.value }}</span>
                </li>
                <li>
                  公办养老机构 <span>{{ insmes.bed.pubInsNum.value }}</span>
                </li>
                <li>
                  民办养老机构
                  <span>{{ insmes.bed.peopleOldInsNum.value }}</span>
                </li>
                <li>
                  医养中心 <span>{{ insmes.bed.daNum.value }}</span>
                </li>
                <li>
                  失能半失能中心 <span>{{ insmes.bed.disableNum.value }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="item">
            <div class="title">入住数据</div>
            <div class="conten">
              <ul>
                <li>
                  入住总数
                  <span class="spred">{{ insmes.moveIn.pubInsNum.value }}</span>
                </li>
                <li>
                  公办养老机构 <span>{{ insmes.moveIn.pubInsNum.value }}</span>
                </li>
                <li>
                  民办养老机构
                  <span>{{ insmes.moveIn.peopleOldInsNum.value }}</span>
                </li>
                <li>
                  医养中心 <span>{{ insmes.moveIn.daNum.value }}</span>
                </li>
                <li>
                  失能半失能中心
                  <span>{{ insmes.moveIn.disableNum.value }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="boxitem2">
          <div class="item">
            <div class="title">服务人员</div>
            <div class="conten">
              <ul>
                <li>
                  总数据
                  <span class="spred">{{ insmes.services.total.value }}</span>
                </li>
                <li>
                  执业医师指标
                  <span>{{ insmes.services.doctorNum.value }}</span>
                </li>
                <li>
                  护士护工数据 <span>{{ insmes.services.nurseNum.value }}</span>
                </li>
                <li>
                  护理人员数
                  <span>{{ insmes.services.nurseCareNum.value }}</span>
                </li>
              </ul>
              <div class="data_box">
                <div
                  id="occupancyRatio"
                  style="width: 100%; height: 100%"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//引入echarts
let echarts = require('echarts')
import { getAreaInsProfile } from '@/api/secondaryPage'
export default {
  //注册组件
  components: {},
  data() {
    return {
      insmes: {
        ins: {
          daInsNum: { key: '医养中心数', value: 0 },
          disableInsNum: { key: '失能半失能中心数', value: 0 },
          insNum: { key: '机构总数', value: 14 },
          peopleInsNum: { key: '民办机构数', value: 0 },
          pubInsNum: { key: '公办机构数', value: 0 },
        }, //机构数
        bed: {
          daNum: { key: '医养中心', value: 0 },
          disableNum: { key: '失能半失能中心', value: 0 },
          peopleInsNum: { key: '民办机构数', value: 0 },
          peopleOldInsNum: { key: '民办养老机构', value: 0 },
          pubInsNum: { key: '公办养老机构', value: 0 },
        }, //床位数
        moveIn: {
          daNum: { key: '医养中心', value: 0 },
          disableNum: { key: '失能半失能中心', value: 0 },
          doctorNum: { key: '执业医师人数', value: 0 },
          nurseCareNum: { key: '护理护工人数', value: 0 },
          nurseNum: { key: '护理护士人数', value: 0 },
          peopleInsNum: { key: '民办机构数', value: 0 },
          peopleOldInsNum: { key: '民办养老机构', value: 0 },
          pubInsNum: { key: '公办养老机构', value: 0 },
          total: { key: '总人数', value: 0 },
        }, //入住数
        services: {
          doctorNum: { key: '执业医师人数', value: 0 },
          nurseCareNum: { key: '护理护工人数', value: 0 },
          nurseNum: { key: '护理护士人数', value: 0 },
          total: { key: '总人数', value: 0 },
        }, //服务人员数量
        careLevel: {
          total: 0, //总数
          list: [
            {
              total: 2, //总数
              num: 0, //值
              name: '自理',
              percent: '20%', //比例值
            },
            {
              total: 5, //总数
              num: 0, //值
              name: '名字',
              percent: '20%', //比例值
            },
          ],
        }, //老人自理介助占比
        train: {
          manTime: {
            total: 0, //社工组织总人数
            accTotal: 0, //累计培训人数 0 社工组织 (培训人数)
            social: 0, //社工组织（培训人数）
            volunteer: 0, //志愿者
          }, //人次数据
          manNum: {
            total: 0, //社工组织总人数
            accTotal: 0, //累计培训人数 0 社工组织 (培训人数)
            social: 0, //社工
            volunteer: 0, //志愿者人数
          },
        }, //组织培训
      },
    }
  },
  mounted() {
    // 入驻人员各护理级别人数及比值
    // let type = parseInt(this.$route.query.type);
    // this.type = type
    // let thisdata = this.$store.state.leftcontent.erjidata;
    // this.data = thisdata[2];
    // console.log(this.data.oldtype)
    this.getData()
  },
  methods: {
    getchart() {
      const data = this.insmes.careLevel.lists.map((item) => {
        const copy = item
        return { name: copy.name, value: copy.total }
      })
      const key = this.insmes.careLevel.lists.map((item) => {
        const copy = item
        return copy.name
      })

      var occupancyRatio = echarts.init(
        document.getElementById('occupancyRatio')
      )
      // 绘制图表
      occupancyRatio.setOption({
        color: [
          '#FF9F1B',
          '#30F279',
          '#359DFF',
          '#FF35F0',
          '#bba840',
          '#ee82ee',
        ],

        legend: {
          orient: 'vertical',
          left: 10,
          top: 20,
          bottom: 20,
          textStyle: {
            color: '#B4C6FE',
          },
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [10, 50],
            center: ['70%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              formatter: '{b}\n {d}%',
              //   position: 'outer',
              //   alignTo: 'edge',
              //   margin: 10,
            },
            // data: [
            //   { value: 40, name: 'rose 1' },
            //   { value: 38, name: 'rose 2' },
            //   { value: 32, name: 'rose 3' },
            //   { value: 30, name: 'rose 4' },
            // ],
            data: data,
          },
        ],
      })
    },
    //获取数据
    getData() {
      const { code } = this.$route.query
      //   let areaCode = 511822
      getAreaInsProfile(code).then((res) => {
        if (res.data.code === 0) {
          this.insmes = res.data.data
          this.getchart()
        }
      })
    },
  },
}
</script>
<style scoped>
.service {
  color: #ffffff;
  background: url(../../../../image/twoThreeImgs/region.png) 0 0 no-repeat;
  background-size: 100% 100%;
  margin-bottom: 0.3rem;
  position: relative;
  height: 18.1rem;
}
.service-title {
  height: 1.56rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.service-main {
  padding: 0.5rem 0.4rem;
}
.boxitem1 {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
}
.boxitem2 {
  margin-top: 0.7rem;
}
.boxitem1 .item {
  /* border: 1px solid red; */
  /* width: 100%; */
}
.boxitem1 .item .conten {
  height: 6.9rem;
  padding: 0.2rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: 7.3rem;
  background: url(../../../../image/twoThreeImgs/boxitem1.png) 0 0 no-repeat;
  background-size: 100% 100%;
}
.boxitem2 .conten {
  /* background: url(../../../../image/twoThreeImgs/boxitem1.png) 0 0 no-repeat; */
  /* background-size: 100% 100%; */
  display: flex;
  align-items: center;
  height: 5.8rem;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
}
.boxitem2 .conten ul {
  width: 7.3rem;
  /* border: 1px solid red; */
}
.data_box {
  height: 5.8rem;
  /* border: 1px solid rgb(1 48, 143, 143); */
  margin-left: 0.4rem;
  width: calc(100% - 7.4rem);
}
.boxitem1 .item .title,
.boxitem2 .item .title {
  color: #69b1ff;
  font-size: 0.58rem;
  margin-bottom: 0.3rem;
  /* padding: 0 0.4rem; */
  font-weight: bold;
}
.conten ul {
  width: 100%;
}
.conten ul li {
  /* border: 1px solid red; */
  color: #8eb8e6;
  font-size: 0.58rem;
  /* margin-top: 0.4rem;
  margin-bottom: 0.25rem; */
  padding: 0 0.4rem;
  height: 1.2rem;
  line-height: 1.2rem;
}
.conten ul {
  /* display: flex; */
}
.conten ul li .spred {
  color: #ff8529;
}
.conten ul li span {
  padding-right: 0.8rem;
  float: right;
  font-size: 0.58rem;
}
</style>
