<template>
  <div class="rightItem" style="width: 24rem;">
    <!-- 区域内养老机构概况 -->
    <region></region>
    <!-- 组织培训 -->
    <organization></organization>
    <!-- <div class="under_box"> -->
    <!-- 安全 -->
    <safe></safe>
    <!-- 监控 -->
    <monitor></monitor>
    <!-- </div> -->
  </div>
</template>
<script>
import region from './components/region.vue'
import organization from './components/organization.vue'
import monitor from './components/monitor.vue'
import safe from './components/safe.vue'

export default {
  //注册组件
  components: {
    region,
    organization,
    monitor,
    safe,
  },
}
</script>
<style>
.under_box {
  /* display: flex; */
  /* width: 1.4rem; */
}
</style>
