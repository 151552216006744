<template>
  <!-- 区域地图 -->
  <div class="areaMap">
    <div class="all-institutions">
      <div class="all-statistics">
        <div class="statistics-item">
          <div class="fist-item">
            {{ numberFormat(statisticsData.totals) }}
          </div>
          <div>用户总数</div>
        </div>
        <div class="statistics-item">
          <div>
            {{ numberFormat(statisticsData.vipTotal) }}
          </div>
          <div>会员总数</div>
        </div>
        <div class="statistics-item">
          <div>
            {{ numberFormat(statisticsData.amount) }}
          </div>
          <div>累计消费金额</div>
        </div>
        <div class="statistics-item">
          <div>
            {{ numberFormat(statisticsData.total) }}
          </div>
          <div>累计消费人数</div>
        </div>
        <div class="statistics-item">
          <div>
            {{ numberFormat(statisticsData.monthTotal) }}
          </div>
          <div>月度消费人次</div>
        </div>
        <div class="statistics-item">
          <div>
            {{ numberFormat(statisticsData.dayTotal) }}
          </div>
          <div>日均消费人次</div>
        </div>
      </div>
      <div class="map-box">
        <div class="chartBox">
          <div class="region">
            <div id="region" class="regionC"></div>
          </div>
          <div class="flag-i">
            <div class="flag-text">
              <img
                src="../../../../image/twoThreeImgs/yanglaojigou.png"
                alt=""
              />
              <div>养老机构</div>
            </div>
            <div class="flag-text">
              <img src="../../../../image/twoThreeImgs/zongheti.png" alt="" />
              <div>养老服务综合体</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getAreaAll, getAreaList } from '@/api/districtCounty/middle.js'
import echarts from 'echarts'
import axios from 'axios'

export default {
  data() {
    return {
      chinaId: null,
      myChart: null,
      chinaName: '富顺县',
      insData: [],
      statisticsData: {
        amount: 0, // 累计消费金额
        totals: 0, // 用户总数
        vipTotal: 0, // 会员总数
        dayTotal: 0, // 日均消费人次
        monthTotal: 0, // 月度消费人次
        total: 0, // 累计消费人数
      },
    }
  },
  mounted() {
    window.detailClick = this.detailClick
    this.chinaId = this.$route.query.code
    this.getAreaAll()
    this.getAreaList()
  },
  methods: {
    getAreaAll() {
      getAreaAll({ code: this.chinaId }).then((res) => {
        if (res.data.code == 0) {
          let data = res.data.data
          this.statisticsData = {
            amount: data.amount,
            totals: data.customer.total,
            vipTotal: data.customer.vipTotal,
            dayTotal: data.dayTotal,
            monthTotal: data.monthTotal,
            total: data.total,
          }
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getAreaList() {
      getAreaList({ code: this.chinaId }).then((res) => {
        let data = res.data.data
        if (res.data.code == 0) {
          data.forEach((item) => {
            item['coord'] = [item.lng, item.lat]
          })
          for (let i = data.length - 1; i >= 0; i--) {
            if (data[i].storeType == 3) {
              data.splice(i, 1)
            }
          }
          this.insData = data
          this.mapChart('region')
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    detailClick(data) {
      data['code'] = this.$route.query.code
      data['companyName'] = this.$route.query.name
      this.$router.push({
        path: '/insComplex',
        query: data,
      })
    },
    mapChart(divid) {
      axios
        .get(
          `https://geo.datav.aliyun.com/areas_v3/bound/geojson?code=${this.chinaId}`
        )
        .then((response) => {
          const mapJson = response.data
          this.myChart = echarts.init(document.getElementById(divid))
          registerAndsetOption(
            this.myChart,
            this.chinaName,
            mapJson,
            this.insData
          )
        })
    },
    numberFormat(value) {
      var param = {}
      var k = 10000,
        sizes = ['', '万', '亿', '万亿'],
        i
      if (Number(value) < k) {
        param.value = value
        param.unit = ''
      } else {
        i = Math.floor(Math.log(value) / Math.log(k))
        param.value = (value / Math.pow(k, i)).toFixed(2)
        param.unit = sizes[i]
      }
      return param.value + param.unit
    },
  },
}

function registerAndsetOption(myChart, name, mapJson, data) {
  echarts.registerMap(name, mapJson)
  myChart.setOption({
    tooltip: {
      show: true,
      trigger: 'item',
      triggerOn: 'click',
      enterable: true,
      extraCssText: 'z-index: 99;max-width: 200px;background-color:transparent',
      position: function (point, params, dom, rect, size) {
        //其中point为当前鼠标的位置，size中有两个属性：viewSize和contentSize，分别为外层div和tooltip提示框的大小
        var x = point[0]
        var y = point[1]
        var boxWidth = size.contentSize[0]
        var boxHeight = size.contentSize[1]
        var posX = 0 //x坐标位置
        var posY = 0 //y坐标位置
        if (x < boxWidth) {
          //左边放不开
          posX = 5
        } else {
          //左边放的下
          posX = x - boxWidth + 100
        }

        if (y < boxHeight) {
          //上边放不开
          posY = 5
        } else {
          //上边放得下
          posY = y - boxHeight
        }

        return [posX, posY]
      },
      formatter: function (params) {
        const result = params.data
        return `
            <div class='map-tools'>
                <div>${result.name}<div/>
                <div class='map-tools-item'>
                    <span>
                        运营单位：
                    </span>
                    <span class='operation-unit'>
                        ${result.spName}
                    </span>
                <div/>
                <div class='map-tools-item'>
                    <span>
                        经营面积：
                    </span>
                    <span class='map-tools-num'>
                        ${result.area}㎡
                    </span>
                <div/>
                <div class='map-tools-item'>
                    <span>
                        入住率：
                    </span>
                    <span class='map-tools-num'>
                        ${result.percent}
                    </span>
                    <span onclick='detailClick(${JSON.stringify(
                      result
                    )})' class='map-tools-detail'>
                          查看详情
                    </span>
                <div/>
            <div/>
                `
      },
    },
    geo: {
      type: 'map',
      map: name,
      roam: true,
      scaleLimit: {
        //滚轮缩放的极限控制
        min: '1',
        max: '3',
      },
      center: undefined,
      itemStyle: {
        normal: {
          areaColor: '#0C0B50',
          borderColor: '#6F86BC',
          borderWidth: 1,
        },
      },
      emphasis: {
        itemStyle: {
          areaColor: '#0C0B50',
        },
        label: {
          show: false,
          color: '#ffffff',
        },
      },
      data: initMapData(mapJson),
    },
    series: [
      {
        type: 'custom',
        coordinateSystem: 'geo',
        markPoint: {
          symbol: (val, e) => {
            // storeType: 0 养老综合体, 1 敬老院，2 养老院 ， 3 政府居家
            if (e.data.storeType == 1 || e.data.storeType == 2) {
              return (
                'image://' +
                require('../../../../image/twoThreeImgs/yanglaojigou.png')
              ) // 机构图片路径
            } else if (e.data.storeType == 0) {
              return (
                'image://' +
                require('../../../../image/twoThreeImgs/zongheti.png')
              ) // 综合体图片路径
            }
          },
          //symbolSize: [50, 50], // 图片大小
          label: {
            position: 'bottom',
            color: '#fff',
            normal: {
              show: true,
              formatter: function (params) {
                var strs = params.name.split('')
                var str = ''
                for (var i = 0, s; (s = strs[i++]); ) {
                  str += s
                  if (!(i % 8)) str += '\n'
                }
                return str
              },
              padding: [75, 0, 0, 0],
              color: '#ffffff',
              fontSize: 11,
              fontWeight: 'normal',
            },
          },
          data: data,
        },
      },
    ],
  })
}
function initMapData(mapJson) {
  var mapData = []
  for (var i = 0; i < mapJson.features.length; i++) {
    mapData.push({
      name: mapJson.features[i].properties.name,
    })
  }
  return mapData
}
</script>
<style scoped>
.all-institutions {
  background: url('../../../../image/top/mapback.png') 0 0 no-repeat;
  background-size: 100% 100%;
  height: 28rem;
  margin-bottom: 0.3rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.all-statistics {
  display: flex;
  justify-content: space-around;
  background: rgba(5, 13, 38, 0.31);
  border-radius: 2px;
  border: 1px solid #213060;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
}
.statistics-item {
  display: flex;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #b4d3fe;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

.statistics-item > div:first-child {
  font-size: 1rem;
  line-height: 1rem;
}
.statistics-item > div:nth-child(2) {
  line-height: 1rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
.fist-item {
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #ff8529;
}
.chartBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
}
.flag-i {
  position: absolute;
  right: 0.5rem;
  bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.flag-i > div:first-child {
  margin-right: 0.3rem;
}
.flag-text {
  padding: 0.2rem 0.5rem 0.45rem;
  text-align: center;
  background: rgba(2, 11, 40, 0.5);
  border-radius: 2px;
  border: 1px solid #213060;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
.region,
.map-box {
  width: 100%;
  height: 100%;
}
.regionC {
  width: 100%;
  height: 100%;
}
</style>
<style>
.map-tools {
  padding: 0.3rem 0.5rem 0.5rem 0.5rem;
  background: url('../../../../image/twoThreeImgs/fukuang.png') 0 0 no-repeat;
  background-size: 100% 100%;
}
.map-tools > div:first-child {
  white-space: normal;
  font-size: 0.6rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.map-tools-item > .operation-unit {
  white-space: normal;
  word-break: break-all;
  text-align: left;
}
.map-tools-item {
  font-size: 0.5rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8eb8e6;
}
.map-tools-item > span:first-child {
  display: inline-block;
  width: 60px;
  text-align: right;
}
.map-tools-num {
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff8529;
  margin-right: 5px;
}
.map-tools-detail {
  cursor: pointer;
  color: #ff8529;
}
</style>
